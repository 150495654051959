import React, { useEffect } from "react";
import { Box, Typography, Container, Grid, Button } from "@mui/material";
import logo from "../../Assets/images/logo.png";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Box sx={style.main}>
      <Container sx={style.container}>
        <Grid container paddingY="3rem" spacing={4}>
          <Grid item lg={4} xs={12}>
            <Box sx={style.box}>
              <img
                alt="logo"
                src={logo}
                style={{ height: "90px", width: "90px" }}
              />
              <Typography sx={style.text}>
                Say goodbye to high fees and impersonal touch. At Grandview
                Getaway, we offer a direct booking experience that ensures
                seamless reservations with a personal touch. While we're listed
                on Airbnb and VRBO, we invite you to book through our website
                for exclusive offers.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Box sx={style.box1}>
              <Typography variant="h4" sx={style.linkHeading}>
                Quick Link
              </Typography>
              <Box sx={{ marginTop: "1rem" }}>
                <Link to={"/"} style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "white",
                      paddingY: "0.3rem",
                      fontFamily: "Poppins",
                    }}
                  >
                    HOME
                  </Typography>
                </Link>
                <Link to={"/Vacation"} style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "white",
                      paddingY: "0.3rem",
                      fontFamily: "Poppins",
                    }}
                  >
                    VACATION HOME
                  </Typography>
                </Link>
                <Link to={"/events"} style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "white",
                      paddingY: "0.3rem",
                      fontFamily: "Poppins",
                    }}
                  >
                    ENTERTAINMENTS & RECOMANDATIONS
                  </Typography>
                </Link>
                <Link to={"/plan"} style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "white",
                      paddingY: "0.3rem",
                      fontFamily: "Poppins",
                    }}
                  >
                    PLANS
                  </Typography>
                </Link>
                <Link to={"/Rental"} style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "white",
                      paddingY: "0.3rem",
                      fontFamily: "Poppins",
                    }}
                  >
                    GALLERY
                  </Typography>
                </Link>
                <Link to={"/specials"} style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "white",
                      paddingY: "0.3rem",
                      fontFamily: "Poppins",
                    }}
                  >
                    SPECIALS
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Box sx={{ width: "100%" }}>
              <Typography variant="h4" sx={style.linkHeading}>
                Contact Info
              </Typography>
              <Typography>
                <a
                  href="mailto:info@grandviewgetaway.com"
                  style={{ color: "white" }}
                >
                  info@grandviewgetaway.com
                </a>
              </Typography>
            </Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: 20,
                alignItems: "center",
                height: "100%",
              }}
            >
              <a class="button-2" role="button" href="/plan">
                Direct Booking
              </a>
              <a
                class="button-2"
                role="button"
                target="_blank"
                href="https://t.vrbo.io/fZwwRWAkzCb"
              >
                Book On Vrbo
              </a>
              <a
                class="button-2"
                role="button"
                target="_blank"
                href="https://air.tl/MoXlLSAA"
              >
                Book On Airbnb
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
const style = {
  main: {
    backgroundColor: "black",
    width: "100%",
    height: "100%",
    borderTop: "1px solid white",
    borderBottom: "1px solid white",
  },
  container: {
    paddingy: "2rem",
    maxWidth: { lg: "1400px" },
  },
  box: {
    width: "100%",
    height: "100%",
    display: { lg: "block", xs: "flex" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  box1: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: { xs: "center", sm: "left" },
  },
  text: {
    color: "white",
    fontSize: "0.9rem",
    width: "80%",
    marginTop: "0.5rem",
    textAlign: { lg: "left", xs: "center" },
  },
  linkHeading: {
    color: "white",
  },
};
