import React from "react";
import Layout from "../../components/Layout/Layout";

const Cancel = () => {
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "black",
          height: "100vh",
        }}
      >
        <div style={{ fontSize: "55px", color: "white" }}>
          Reservation Canceled back To{" "}
          <a href="/home" style={{ textDecoration: "none" }}>
            Home
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default Cancel;
