import "./App.css";
import Resort from "./components/OurResort/Resort";
import Specials from "./components/our Specials/Specials";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Rental from "./Pages/Rental";
import Contact from "./Pages/ContactUs";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Event } from "./Pages/Events/Event";
import Plan from "./Pages/Plans/Plan";
import { SuccessDisplay } from "./Pages/Success/Success";
import Cancel from "./Pages/Cancel/Cancel";
import Policy from "./Pages/PrivacyPolicy/Policy";
import Terms from "./Pages/TermAndCondition/Terms";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 200,
    });
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Vacation" element={<Resort />} />
      <Route path="/events" element={<Event />} />
      <Route path="/specials" element={<Specials />} />
      <Route path="/Rental" element={<Rental />} />
      <Route path="/plan" element={<Plan />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/success" element={<SuccessDisplay />} />
      <Route path="/cancle" element={<Cancel />} />
      <Route path="/privacy-policy" element={<Policy />} />
      <Route path="/terms-condition" element={<Terms />} />
    </Routes>
  );
}

export default App;
