import React from "react";
import Layout from "../../components/Layout/Layout";
import banner from "../../Assets/planbg.jpg";
import { Box, Container, Grid, Typography } from "@mui/material";
import location from "../../Assets/loca.jpg";
import PLanSection from "./PLanSection";
const Plan = () => {
  const dumm = `
  <div style="color: white;">
  <div>
    <strong>Rules</strong>
    <ul>
      <li>No pets unless they are service animals</li>
      <li>Please remove your shoes at the entry doors.</li>
      <li>If you smoke outside, please be extremely cautious of wildfires and put any embers out in water.</li>
      <li>6 guests max. Including children.</li>
    </ul>
  </div>

  <div>
    <strong>Checkout</strong>
    <ul>
      <li>Dirty towels go in the laundry basket.</li>
      <li>Please make sure all leftovers are removed from the house unless they are unopened and can be shared.</li>
      <li>Garbage can be thrown in the large can out back but must be in a kitchen bag with a drawstring tie.</li>
      <li>Please wash all dirty dishes, either by hand or in the dishwasher.</li>
      <li>Please put the key back in the lockbox on the door handle and spin the numbers so it locks.</li>
    </ul>
  </div>

  <div>
    <strong>Basics</strong>
    <ul>
      <li>Please enjoy the many streaming stations we have available for our guests: Hulu+Live+Disney+ESPN, MAX, Netflix, Paramount+.</li>
      <li>The Traeger Smoker outside runs on wood pellets. Please make sure the hopper is full while smoking. BBQ utensils are stored in the kitchen island. Grease goes in the garbage when it's cool, not down the drain.</li>
      <li>There is an extra propane tank for the fire pit around the back of the house along with 2 extra folding Adirondack chairs.</li>
    </ul>
  </div>

  <div>
    <strong>Check in</strong> -  3pm-12am<br><br>
    <strong>Check out</strong> - 10am<br><br>
    Address, directions, door code, and Wifi login will be sent privately to the Guests contact information one week prior to stay.
  </div>
<br>  
  <div>
    <strong>Cancellation Policy</strong>
    <p>Policy : Relaxed</p>
    <ul>
      <li>100% refund - For cancellation more than 14 days before check-in</li>
      <li>50% refund - For cancellation more than 7 days before check-in</li>
      <li>No refund - For cancellation less than 7 days before check-in</li>
    </ul>
    <p>All refunds will be returned to purchasing card on file.</p>
  </div>
</div>
  `;
  return (
    <>
      <Layout>
        <Box sx={styles.box}>
          <img src={banner} style={{ height: "100%", width: "100%" }} />
          <Box sx={styles.absolute}>
            <Box sx={styles.col} className="text-holder">
              <Typography
                className="text-holderh3"
                sx={[
                  styles.colr,
                  {
                    fontWeight: 700,
                    fontSize: {
                      xl: "115px",
                      lg: "95px",
                      md: "80px",
                      sm: "50px",
                      xs: "35px",
                    },
                    fontFamily: "Poppins",
                    textShadow: "2px 2px 4px #000",
                  },
                ]}
              >
                PLANS
              </Typography>
            </Box>
          </Box>
        </Box>

        <PLanSection />
        <Box sx={{ background: "black", paddingY: "3rem", width: "100%" }}>
          <Container sx={{ maxWidth: { lg: "1350px" } }}>
            <Typography
              sx={{ fontWeight: 700, color: "white", fontSize: "30px" }}
            >
              Additional Information
            </Typography>
            <Grid container spacing={5}>
              <Grid item md={6} xs={12}>
                <div dangerouslySetInnerHTML={{ __html: dumm }} />
              </Grid>
              <Grid item md={6} xs={12}>
                <img
                  src={location}
                  alt="ooops"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Layout>
    </>
  );
};

export default Plan;
const styles = {
  box: {
    width: "100%",
    position: "relative",
    height: { md: "900px", sm: "auto", xs: "400px" },
  },
  absolute: {
    position: "absolute",
    top: 0,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  col: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    gap: "10px",
  },
  colr: {
    color: "#FFF",
    textAlign: "center",
  },
};
