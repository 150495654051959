import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Button, Typography } from "@mui/material";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import slid1 from "../../Assets/images/slid1.png";
import slid2 from "../../Assets/images/slid2.png";
import slid3 from "../../Assets/images/slid3.png";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import EastIcon from "@mui/icons-material/East";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SwiperCore from "swiper";
import { HiArrowNarrowLeft } from "react-icons/hi";
// Import Swiper styles
import "swiper/css";
import { useSwiper } from "swiper/react";
const styles = {
  main: {
    backgroundColor: "black",
    paddingY: "130px",
    position: "relative",
    marginTop: "1rem",
  },
  slid: {
    backgroundColor: "rgb(255, 255, 255, 0.1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    borderRadius: "50px",
    fontFamily: "Poppins",
    flexDirection: { md: "row", xs: "column" },
  },

  col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: { md: "start", xs: "center" },
    gap: "40px",
    marginY: { md: "0px", xs: "14px" },
  },
  colr: {
    color: "#FFF",
    fontWeight: "400",
    fontFamily: "Poppins,sans-serif",
    fontSize: "14px",
  },
  colr2: {
    color: "#FFF",
    fontSize: "15px",
    fontWeight: "500",
    fontFamily: "Poppins,sans-serif",
  },
  colr3: {
    color: "#FFF",
    fontSize: { xl: "20px", lg: "20px" },
    fontWeight: "600",
  },
  cont: {
    maxWidth: { lg: "1200px", xl: "1500px" },
    paddingY: "40px",
  },
  grd: {
    width: "100%",

    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "30px",
  },
  col3: {
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    backgroundColor: "#0082CA",
    paddingY: "12px",
    paddingX: "17px",
    color: "white",
    fontFamily: "Poppins,sans-serif",

    borderRadius: "15px",

    ":hover": {
      backgroundColor: "white",
      color: "#0082CA",
    },
  },
};
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
function SliderComp() {
  const swiper = useSwiper();
  const swiperRef = useRef(null);

  const handleNext = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const data = [
    {
      head: "Breathtaking Views",
      desc: "Experience nature's masterpiece at Grandview Getaway. Our picturesque location offers sweeping views that will leave you in awe, making every moment a postcard-worthy memory. ",
      src: slid1,
    },
    {
      head: "Unmatched Relaxation",
      desc: "Indulge in relaxation during your stay at Grand View Getaway. From all amenities to home-like decor, you'll be sure to book with us again and again ",
      src: slid3,
    },
    {
      head: "A World of Adventure",
      desc: "Embrace the spirit of adventure at Grandview Getaway. Whether it's fishing, hunting, hiking, or simply exploring the wonders of nature, our retreat offers an abundance of thrilling experiences.",
      src: slid2,
    },
    {
      head: "Your Tranquil Escape",
      desc: "Step into a world of tranquility at Grandview Getaway. Immerse yourself in the serenity of beautiful surroundings, and let the stress of everyday life melt away in the lap of nature's embrace.",
      src: slid2,
    },
  ];
  return (
    <Box sx={styles.main}>
      <Swiper
        ref={swiperRef}
        spaceBetween={50}
        // slidesPerView={2}
        navigation={false} // Disabling Swiper's default navigation
        // pagination={{ clickable: true }}
        autoplay
        loop
        centeredSlides={true}
        breakpoints={{
          // When window width is >= 768px
          320: {
            slidesPerView: 1,
          },
          // 768: {
          //   slidesPerView: 2,
          // },
          // When window width is >= 992px
          1317: {
            slidesPerView: 2,
          },
        }}
      >
        {data.map(({ head, desc, src }) => (
          <SwiperSlide>
            <Box sx={styles.slid}>
              <Box sx={styles.col}>
                <Typography
                  sx={[styles.colr2, { fontSize: "20px !important" }]}
                >
                  {head}
                </Typography>
                <Typography sx={styles.colr}>{desc}</Typography>
                <Box sx={styles.row}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 20,
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <a
                      class="button-2"
                      role="button"
                      target="_blank"
                      href="https://t.vrbo.io/fZwwRWAkzCb"
                    >
                      Book On Vrbo
                    </a>
                    <a
                      class="button-2"
                      role="button"
                      target="_blank"
                      href="https://air.tl/MoXlLSAA"
                    >
                      Book On Airbnb
                    </a>
                  </div>

                  {/* <Box sx={styles.col3}>
                    <Typography sx={styles.colr2}>CALL US NOW</Typography>
                    <Typography sx={styles.colr3} textAlign={"left"}>
                      + 425-765-2031
                    </Typography>
                  </Box> */}
                </Box>
              </Box>
              <Box>
                <img src={src} style={{ width: "400px", height: "auto" }} />
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        sx={{
          color: "#FFF",
          fontSize: "80px",
          position: "absolute",

          top: 50,
          right: 290,

          zIndex: 1000,
        }}
        onClick={handlePrev}
      >
        <EastIcon
          sx={{ fontSize: "60px", color: "#0082CA", cursor: "pointer" }}
        />
      </Box>
      <Box
        sx={{
          color: "#FFF",
          position: "absolute",
          top: 70,
          right: 360,
          zIndex: 1000,
        }}
        onClick={handleNext}
      >
        <HiArrowNarrowLeft style={{ fontSize: "60px", cursor: "pointer" }} />
      </Box>
    </Box>
  );
}

export default SliderComp;
