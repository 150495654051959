import React, { useCallback, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Typography } from "@mui/material";
import banner from "../../Assets/AirbnbPic/Living Area/6.webp";
import banner1 from "../../Assets/AirbnbPic/Veiws/2.avif";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa";

import "swiper/css";

const styles = {
  box: {
    width: "100%",
    position: "relative",
    height: { md: "900px", xs: "600px" },
  },
  absolute: {
    position: "absolute",
    top: 0,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  col: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    gap: "10px",
  },
  colr: {
    color: "#FFF",
    textAlign: "center",
  },
};
function Slider() {
  // SwiperCore.use([Autoplay]);
  const sliderRef = useRef(null);
  const [active, setActive] = useState("prev");
  const handlePrev = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
    setActive("prev");
    console.log("click prev");
  };

  const handleNext = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    setActive("next");
  };

  return (
    <div style={{ position: "relative" }}>
      <Swiper
        ref={sliderRef}
        modules={[Navigation, Pagination, Scrollbar, Autoplay]}
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop={true}
        Navigation={true}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        allowTouchMove={false}
        // Disable swiping to next slide
        data-swiper-autoplay="2000"
      >
        <SwiperSlide>
          <Box sx={styles.box}>
            <img src={banner1} style={{ height: "100%", width: "100%" }} />
            <Box sx={styles.absolute}>
              <Box sx={styles.col} className="text-holder">
                <Typography
                  sx={[
                    styles.colr,
                    {
                      fontWeight: 600,
                      fontSize: {
                        xl: "67px",
                        lg: "50px",
                        md: "45px",
                        sm: "30px",
                        xs: "18px",
                      },
                      fontFamily: "Poppins",
                    },
                  ]}
                  data-aos="fade-right"
                  data-aos-delay="700"
                >
                  WELCOME TO
                </Typography>
                <Typography
                  className="text-holderh3"
                  // data-aos="fade-right"
                  // data-aos-delay="500"
                  sx={[
                    styles.colr,
                    {
                      fontWeight: 700,
                      fontSize: {
                        xl: "115px",
                        lg: "95px",
                        md: "80px",
                        sm: "50px",
                        xs: "35px",
                      },
                      fontFamily: "Poppins",
                      textShadow: "2px 2px 4px #000",
                    },
                  ]}
                >
                  Grandview Getaway
                </Typography>
                <Typography
                  variant="h6"
                  sx={[
                    styles.colr,
                    {
                      fontWeight: 500,
                      marginY: { xl: "30px", lg: "20px", md: "15px" },
                      fontFamily: "Poppins",
                    },
                  ]}
                >
                  Where relaxsation meets the outdoors!
                </Typography>
              </Box>
            </Box>
          </Box>
        </SwiperSlide>

        <div className="prev-arrow">
          <FaArrowRight
            onClick={handlePrev}
            style={{
              color: active === "prev" ? "#09B0D0" : "inherit",
              fontSize: active === "prev" ? 19 : 16,
              marginLeft: "5px",
              transition: "all ease-out 0.5s",
            }}
          />
        </div>
        <div className="next-arrow">
          <FaArrowLeft
            onClick={handleNext}
            style={{
              color: active === "next" ? "#09B0D0" : "inherit",
              fontSize: active === "next" ? 19 : 16,
              marginRight: "5px",
              transition: "all ease-out 0.5s",
            }}
          />
        </div>
      </Swiper>
    </div>
  );
}

export default Slider;
