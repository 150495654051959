import { Box, Typography } from "@mui/material";
import React from "react";
import Layout from "../Layout/Layout";
import banner from "../../Assets/images/bgs.jpg";
import GridSection from "../../Common/GridSection";

import CommonSlider from "../../Common/CommonSlider";
import Amenities from "./Amenities";

function Resort() {
  return (
    <>
      <Box>
        <Layout>
          <CommonSlider text={"VACATION hOME"} image={banner} />
          <GridSection />
          <Amenities />
          {/* <GetInTouch /> */}
        </Layout>
      </Box>
    </>
  );
}

export default Resort;
const style = {
  herBox: {
    width: "100%",
    height: { md: "80vh", xs: "auto" },
    // position: "relative",
    // zIndex: -1,
    backgroundSize: "cover",
    background: `url(${banner}) no-repeat `,
  },
};
