import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Box, Typography } from "@mui/material";
import banner from "../../Assets/images/inbanner02.png";
export const SuccessDisplay = () => {
  let [sessionId, setSessionId] = useState("");
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("session_id")) {
      setSessionId(query.get("session_id"));
    }
  }, []);
  return (
    // <section>
    //   <div className="product Box-root">
    //     <div className="description Box-root">
    //       <h3>Subscription to starter plan successful!</h3>
    //     </div>
    //   </div>
    //   <form action="/create-portal-session" method="POST">
    //     <input
    //       type="hidden"
    //       id="session-id"
    //       name="session_id"
    //       value={sessionId}
    //     />
    //     <button id="checkout-and-portal-button" type="submit">
    //       Manage your billing information
    //     </button>
    //   </form>
    // </section>
    <Layout>
      <Box sx={styles.box}>
        <img src={banner} style={{ height: "100%", width: "100%" }} />
        <Box sx={styles.absolute}>
          <Box sx={styles.col} className="text-holder">
            <Typography
              className="text-holderh3"
              // data-aos="fade-right"
              // data-aos-delay="500"
              sx={[
                styles.colr,
                {
                  fontWeight: 700,
                  fontSize: {
                    xl: "115px",
                    lg: "95px",
                    md: "80px",
                    sm: "50px",
                    xs: "35px",
                  },
                  fontFamily: "Poppins",
                  textShadow: "2px 2px 4px #000",
                },
              ]}
            >
              PAYMENT SUCCESSFULL
            </Typography>

            <a
              class="button-2"
              role="button"
              target="_blank"
              href="https://Grandviewgetaway.com:2083"
            >
              Back to Homepage
            </a>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};
const styles = {
  box: {
    width: "100%",
    position: "relative",
    height: { md: "900px", xs: "600px" },
  },
  absolute: {
    position: "absolute",
    top: 0,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  col: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    gap: "10px",
  },
  colr: {
    color: "#FFF",
    textAlign: "center",
    textTransform: "uppercase",
  },
};
