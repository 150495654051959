import React from "react";
import Layout from "../../components/Layout/Layout";
import { Box, Container } from "@mui/material";

const Policy = () => {
  const privacyPolicyContent = `
  <p>
    <strong>Grandview Getaway is accessible from 
      <a href="https://Grandviewgetaway.com/">https://Grandviewgetaway.com/</a> <br><br>
    </strong>
    one of our main priorities is the privacy of our visitors. Therefore, this 
    <strong>Privacy Policy</strong> document contains information collected and recorded by 
    <strong>Grandview Getaway</strong> and how we use it.
    <br><br>
    If you have additional questions or require more information about our 
    <strong>Privacy Policy</strong>, do not hesitate to contact us.
    <br><br>
    This Privacy Policy applies only to our online activities. It is valid for visitors to our website regarding the information they share and collect in Grandview Getaway. This policy does not apply to any information collected offline or via channels other than this website. 
    <br><br>
    <strong>Consent</strong>
    <br>
    By using our website, you hereby consent to our Privacy Policy and agree to its terms.
    <br><br>
    <strong>Information we collect</strong>
    <br>
    The personal information you are asked to provide and the reasons why you are asked to provide it will be clear to you at the point we ask you to provide your personal information.
    <br><br>
    If you contact us directly, we may receive additional information about you, such as your name, email address, phone number, the contents of the message and attachments you may send us, and any other information you may provide.
    <br><br>
    When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
    <br><br>
    <strong>How we use your information</strong>
    <br>
    We use the information we collect in various ways, including:
    <ul>
      <li>Provide, operate, and maintain our website</li>
      <li>Improve, personalize, and expand our website</li>
      <li>Understand and analyze how you use our website</li>
      <li>Develop new products, services, features, and functionality</li>
      <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website and for marketing and promotional purposes.</li>
      <li>Send you emails</li>
      <li>Find and prevent fraud</li>
    </ul>
    <br>
    <strong>Log Files</strong>
    <br>
    Grandview Getaway follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and are a part of hosting services' analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any personally identifiable information. Instead, the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
    <br><br>
    <strong>Cookies and Web Beacons</strong>
    <br>
    Like any other website, Grandview Getaway uses 'cookies.' These cookies store information, including visitors' preferences and the pages on the website that the visitor accessed or visited. In addition, the information is used to optimize the users' experience by customizing our web page content based on visitor's browser type and other information.
    <br><br>
    <strong>Advertising Partners Privacy Policies</strong>
    <br>
    You may consult this list to find the Privacy Policy for each of the advertising partners of Grandview Getaway.
    <br><br>
    Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons in their respective advertisements and links on Grandview Getaway which are sent directly to users' browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and personalize the advertising content you see on websites you visit.
    <br><br>
    Grandview Getaway has no access to or control of third-party advertisers' cookies.
    <br><br>
    <strong>Third-Party Privacy Policies</strong>
    <br>
    Grandview Getaway’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about opting out of specific options.
    You can choose to disable cookies through your browser options. It can be found on the browsers' respective websites for more detailed information about cookie management with specific web browsers.
    <br><br>
    <strong>CCPA Privacy Rights (Do Not Sell My Personal Information)</strong>
    <br>
    Under the CCPA, among other rights, US consumers have the right to:
    <ul>
      <li>Request that a business that collects a consumer's data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
      <li>Request that a business delete any personal data about the consumer a business has collected.</li>
      <li>Request that a business that sells a consumer's data not sell the consumer's data.</li>
    </ul>
    If you make a request, we have one month to respond to you. Please contact us if you would like to exercise any of these rights.
    <br><br>
    <strong>GDPR Data Protection Rights</strong>
    <br>
    We want to ensure you are fully aware of your data protection rights. Therefore, every user is entitled to the following:
    <ul>
      <li>The right to access – You have the right to request copies of your data. We may charge you a small fee for this service.</li>
      <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
      <li>The right to erasure – You have the right to request that we erase your data under certain conditions.</li>
      <li>The right to restrict processing – You have the right to request that we restrict processing your data under certain conditions.</li>
      <li>The right to object to processing – Under certain conditions, you have the right to object to our processing of your data.</li>
      <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization or directly to you under certain conditions.</li>
    </ul>
    If you make a request, we have one month to respond to you. Please contact us if you would like to exercise any of these rights.
    <br><br>
    <strong>Children's Information</strong>
    <br>
    Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, monitor, and guide their online activity.
    Grandview Getaway does not knowingly collect personally identifiable information from children under 13. However, suppose you think your child provided this information on our website. In that case, we strongly encourage you to contact us immediately, and we will do our best to remove such information from our records promptly.
  <br><br>
   <strong>Cancellation Policy</strong>
   <br>
   Policy : Relaxed
    <br><br>
   <strong>100% refund</strong>
   <br>
   For cancellation more than 14 days before check-in
   <br><br>
   <strong>50% refund</strong>
   <br>
    For cancellation more than 7 days before check-in
    <br><br>
   <strong>No refund</strong>
   <br>
    For cancellation less than 7 days before check-in
    </p>
`;
  return (
    <>
      <Layout>
        <Box sx={{ paddingTop: "10rem", background: "black" }}>
          <Container sx={{ maxWidth: { lg: "1450px" }, paddingY: "2rem" }}>
            <div
              dangerouslySetInnerHTML={{ __html: privacyPolicyContent }}
              style={{ color: "white" }}
            />
          </Container>
        </Box>
      </Layout>
    </>
  );
};

export default Policy;
