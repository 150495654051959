import React from "react";
import { Box, Typography } from "@mui/material";
import banner from "../Assets/images/inbanner01.png";

function CommonSlider({ text, image, show }) {
  return (
    <Box sx={styles.box}>
      <img
        src={image}
        style={{ height: "100%", width: "100%", objectFit: "fill" }}
      />
      <Box sx={styles.absolute}>
        <Box sx={styles.col} className="text-holder">
          <Typography
            className="text-holderh3"
            sx={[
              styles.colr,
              {
                fontWeight: 700,
                fontSize: {
                  xl: "115px",
                  lg: "95px",
                  md: "80px",
                  sm: "50px",
                  xs: "35px",
                },
                fontFamily: "Poppins",
                textShadow: "2px 2px 4px #000",
              },
            ]}
          >
            {text}
          </Typography>

          {show && (
            <Typography
              sx={{
                color: "white",
                fontFamily: "Poppins",
                fontWeight: 500,
                textAlign: "center",
                marginX: "1rem",
              }}
            >
              Discount Codes will be posted on our Social Media for a limited
              time, so be sure to follow us there!
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default CommonSlider;
const styles = {
  box: {
    width: "100%",
    position: "relative",
    height: { md: "900px", xs: "600px" },
  },
  absolute: {
    position: "absolute",
    top: 0,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  col: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    gap: "10px",
  },
  colr: {
    color: "#FFF",
    textAlign: "center",
    textTransform: "uppercase",
  },
};
