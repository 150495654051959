import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import banner from "../../Assets/images/inbanner02.png";
import CommonSlider from "../../Common/CommonSlider";
import Layout from "../../components/Layout/Layout";
import img1 from "../../Assets/tab.jpg";
import resort2 from "../../Assets/images/reso2.png";
import EntertainmentGrid from "../../Common/EntertainmentsGrid";

export const Event = () => {
  const Data = [
    {
      content: `    <h2 style={{marginBottomL:10px}}>Dining:</h2>
            <p>35 min West on Highway 12 in Lewiston. Breakfast - Waffles n More Lunch - Mystic Cafe Dinner - Koi Japanese (reservation recommended) <br/> <br/> 20 min East on highway 12 in Orofino Breakfast - Krystal Cafe’ Lunch - Northfork Cafe’ Dinner - House of JuJu</p>
            <h2 style={{marginBottomL:10px}}>Entertainment:</h2>
            <p>Clearwater Creek Casino, Rivaura Winery, Lindsay Creek Vinyards, Orofino Golf and Country Club, Bryden Canyon Public Golf Course.</p>
            `,
      img: img1,
    },
    {
      content: `<h2 style={{marginBottomL:10px}}>Hunting and Fishing:</h2>  
            <strong style={{marginBottomL:5px}}>Fishing-</strong>
            <p>Hells Canyon Sport Fishing <a href="tel:208-791-0344" > (208) 791-0344 </a> <a href="http://www.hellscanyonsportfishing.com"> www.hellscanyonsportfishing.com </a> <br/> <br/> Reel Time Fishing <a href="tel:208-790-2128" >(208) 790-2128<a/><a href="http://www.reeltimefishing.com" > www.reeltimefishing.com</a></p>
            <strong style={{marginBottomL:5px}}>Hunting-</strong>
            <p>Cayuse Outfitting <a href="tel:208-507-0781" > (208) 507-0781 </a> <br/> <a href="http://www.idahocayuse.com"> www.idahocayuse.com </a> <br/><br/> Rasmussen Outfitters <a href="tel:208-476-7075"> (208) 476-7075 </a> <a href="http://www.rasmussenoutfitters.com"> www.rasmussenoutfitters.com </a></p>
            <strong style={{marginBottomL:5px}}>Hikes-</strong>
            <p>Ahsahka Ridge Trail System, Tamarack Road Hiking, Elk Creek Falls Trailhead, Idler’s Rest Nature Preserve, Hells Gate State Park, Dworshak State Park</p>
            `,
      img: resort2,
    },
  ];
  return (
    <>
      <Box>
        <Layout>
          <CommonSlider
            text={"entertainment & recomandations"}
            image={banner}
          />
          <EntertainmentGrid />
          <Container sx={{ maxWidth: { lg: "1200px" } }}>
            {Data.map(({ content, img }) => (
              <Box sx={styles.slid}>
                <Box
                  sx={styles.col}
                  data-aos="zoom-in-left"
                  data-aos-duration="1500"
                >
                  <Typography
                    sx={{ color: "white", width: "80%" }}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                  <Box sx={styles.row}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <a class="button-2" role="button" href="/paln">
                        Direct Booking
                      </a>
                      <a
                        class="button-2"
                        role="button"
                        target="_blank"
                        href="https://t.vrbo.io/fZwwRWAkzCb"
                      >
                        Book On Vrbo
                      </a>
                      <a
                        class="button-2"
                        role="button"
                        target="_blank"
                        href="https://air.tl/MoXlLSAA"
                      >
                        Book On Airbnb
                      </a>
                    </div>

                    {/* <Box sx={styles.col3}>
                      <Typography sx={styles.colr2}>CALL US NOW</Typography>
                      <Typography sx={styles.colr3} textAlign={"left"}>
                        + 425-765-2031
                      </Typography>
                    </Box> */}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "40px",
                    alignItems: { lg: "start", xs: "center" },
                  }}
                  data-aos="zoom-in-left"
                  data-aos-duration="1500"
                >
                  <img
                    src={img}
                    className="img-tag"
                    style={{
                      border: "5px solid white",
                      borderTopLeftRadius: "180px",
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Container>
        </Layout>
      </Box>
    </>
  );
};
const styles = {
  slid: {
    backgroundColor: "black",
    display: { lg: "flex", xs: "block" },
    justifyContent: { lg: "space-between", xs: "center" },
    alignItems: "center",
    padding: "30px",
    borderRadius: "50px",
    fontFamily: "Poppins",
    marginY: "20px",
  },

  col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "40px",
    alignItems: { lg: "start", xs: "center" },
    marginBottom: { lg: "0px", xs: "14px" },
  },
  colr: {
    color: "#FFF",
    fontWeight: "400",
    fontFamily: "Poppins,sans-serif",
    fontSize: "14px",
  },
  colr2: {
    color: "#FFF",
    fontSize: "15px",
    fontWeight: "500",
    fontFamily: "Poppins,sans-serif",
  },
  colr3: {
    color: "#FFF",
    fontSize: "23px",
    fontWeight: "600",
  },
  cont: {
    maxWidth: { lg: "1200px", xl: "1500px" },
    paddingY: "40px",
  },
  grd: {
    width: "100%",

    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "30px",
  },
  col3: {
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    backgroundColor: "#0082CA",
    paddingY: "12px",
    paddingX: "17px",
    color: "white",
    fontFamily: "Poppins",

    borderRadius: "25px",

    ":hover": {
      backgroundColor: "white",
      color: "#0082CA",
    },
  },
};
