import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { SiHomebridge } from "react-icons/si";
import { MdVilla } from "react-icons/md";

import { FaLayerGroup } from "react-icons/fa";
import { SiMongoosedotws } from "react-icons/si";
import { MdFolderSpecial } from "react-icons/md";
import { MdContactPhone } from "react-icons/md";
export default function MobileDrawer({ toggleDrawer, open, setOpen }) {
  const navItems = [
    {
      name: "Home",
      url: "/",
      icon: <SiHomebridge />,
    },
    {
      name: "Vacation Home",
      url: "/Vacation",
      icon: <MdVilla />,
    },
    {
      name: "Entertainment & Recomandations",
      url: "/events",
      icon: <FaLayerGroup />,
    },
    {
      name: "Plan",
      url: "/plan",
      icon: <SiMongoosedotws />,
    },
    {
      name: "Gallery",
      url: "/Rental",
      icon: <SiMongoosedotws />,
    },
    {
      name: "Specials",
      url: "/specials",
      icon: <MdFolderSpecial />,
    },
    // {
    //   name: "Contacts",
    //   url: "/Contact",
    //   icon: <MdContactPhone />,
    // },
  ];
  const DrawerList = (
    <Box sx={{ width: 270 }} role="presentation" onClick={toggleDrawer(false)}>
      <List sx={{ marginTop: "40px" }}>
        {navItems.map((text, index) => (
          <ListItem key={index} sx={{ marginY: "12px" }} disablePadding>
            <ListItemButton
              sx={{
                ":hover": {
                  background: "#E3E1D9",
                },
              }}
            >
              <ListItemIcon sx={{ fontSize: 30 }}>{text.icon}</ListItemIcon>
              <Link
                to={text.url}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                {/* <ListItemText
                  style={{
                    fontFamily: "Poppins-Regular",

                    fontWeight: 600,
                  }}
                  primary={text.name}
                /> */}
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: 500,
                  }}
                >
                  {text.name}
                </Typography>
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
